import React from 'react'
import Layout from 'src/layouts/BaseLayout'

// components
import Hero from 'src/pages/politica-de-seguranca-para-fornecedores/sections/hero/_index'
import Principios from 'src/pages/politica-de-seguranca-para-fornecedores/sections/principios/_index'
import Abrangencias from 'src/pages/politica-de-seguranca-para-fornecedores/sections/abrangencias/_index'
import Diretrizes from 'src/pages/politica-de-seguranca-para-fornecedores/sections/diretrizes/_index'
import Conformidade from 'src/pages/politica-de-seguranca-para-fornecedores/sections/conformidade/_index'

// assets
import pageContext from './pageContext.json'
import { Wrapper } from './style'

const PoliticaFornecedores = () => {
  const lang = 'en'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
        <Principios lang={lang} />
        <Abrangencias lang={lang} />
        <Diretrizes lang={lang} />
        <Conformidade lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default PoliticaFornecedores
